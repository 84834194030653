import * as React from "react"
import Logo from "../components/logo/logo"
import "../styles/style.css"

// markup
const IndexPage = () => {
  return (
    <div>
      <Logo></Logo>
    </div>
  )
}

export default IndexPage
