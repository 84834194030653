import * as React from "react"
import LogoImg from "./logo.png"

const Logo = () => {
    return (
      <img src={LogoImg} alt="Symmetria Logo" />
    )
  }
  
export default Logo
  